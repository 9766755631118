<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Services</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details rn-section-gap pb-10 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="service-details-content align-items-center pb--80 mb--80"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        src="../assets/images/service/branding.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                        <h3>Branding & Creative</h3>
                      </div>
                      <p>
                        Unlock the true potential of your brand with our expert
                        branding and creative services. We craft compelling
                        brand identities, logos, and visual elements that
                        resonate with your target audience, ensuring your brand
                        stands out in the competitive marketplace.
                      </p>
                      <ul class="liststyle bullet mt--25">
                        <li>Brand Development and Rebranding</li>
                        <li>Logo Design and Visuals</li>
                        <li>Essential Print Materials</li>
                        <li>Digital and Graphic Design</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row
                  class="service-details-content align-items-center pb--80 mb--80"
                >
                  <v-col lg="6" md="6" cols="12" order="2" order-md="1">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                        <h3>Web Design & Development</h3>
                      </div>
                      <p>
                        Transform your online presence with our cutting-edge web
                        design and development services. We create responsive,
                        user-friendly websites that not only look stunning but
                        also drive engagement and conversions. From e-commerce
                        to custom web solutions, we tailor everything to meet
                        your specific needs.
                      </p>
                      <ul class="liststyle bullet mt--25">
                        <li>Modern Sleek Designs</li>
                        <li>Custom Tailored Solutions</li>
                        <li>Fast, Responsive and Easy to Use</li>
                        <li>Web Maintenance and Support</li>
                      </ul>
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12" order="1" order-md="2">
                    <div class="thumb">
                      <img
                        src="../assets/images/service/dev.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row
                  class="service-details-content align-items-center pb--80 mb--80"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        src="../assets/images/service/marketing.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                        <h3>Marketing & Advertising</h3>
                      </div>
                      <p>
                        Elevate your brand's visibility and reach with our
                        comprehensive marketing and advertising strategies. We
                        specialize in SEO, PPC, social media marketing, and
                        content creation, all designed to boost your online
                        presence and deliver measurable results.
                      </p>

                      <ul class="liststyle bullet mt--25">
                        <li>SEO (Search Engine Optimization)</li>
                        <li>PPC (Pay Per Click) Advertising</li>
                        <li>Social Media Management and Content Creation</li>
                        <li>Analytics and Reporting</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row
                  class="service-details-content align-items-center pb--80 mb--80"
                >
                  <v-col lg="6" md="6" cols="12" order="2" order-md="1">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                        <h3>Photo & Video</h3>
                      </div>
                      <p>
                        Tell your story through captivating photo and video
                        content. Our professional photography and videography
                        services capture the essence of your brand, creating
                        powerful visuals that engage and inspire your audience.
                        Whether for social media, commercials, or corporate
                        videos, we've got you covered.
                      </p>

                      <ul class="liststyle bullet mt--25">
                        <li>Professional Photo and Video</li>
                        <li>Drone and Digital</li>
                        <li>Editing and Post-Production</li>
                        <li>Social Media Content</li>
                      </ul>
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12" order="1" order-md="2">
                    <div class="thumb">
                      <img
                        src="../assets/images/service/photo.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row
                  class="service-details-content pb--80 align-items-center"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        src="../assets/images/service/support.png"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title mb--30">
                        <h3>Maintenance & Support</h3>
                      </div>
                      <p>
                        Ensure your digital assets are always performing at
                        their best with our comprehensive maintenance and
                        support services. We provide ongoing assistance to keep
                        your website and marketing campaigns running smoothly,
                        securely, and efficiently.
                      </p>

                      <h4 class="title">What can you expect?</h4>
                      <ul class="liststyle bullet">
                        <li>Website Maintenance</li>
                        <li>Technical Support</li>
                        <li>Content Updates</li>
                        <li>Performance Monitoring</li>
                      </ul>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <UnlockYourBrand />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "../components/header/Header";
import ServiceFour from "../components/service/ServiceFour";
import UnlockYourBrand from "../components/about/UnlockYourBrand";
import Footer from "../components/footer/Footer";
import feather from "feather-icons";
export default {
  components: {
    Header,
    ServiceFour,
    UnlockYourBrand,
    Footer
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          to: "/",
          disabled: false
        },
        {
          text: "Services",
          to: "",
          disabled: true
        }
      ]
    };
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    }
  }
};
</script>

<style scoped>
.thumb img {
  margin: 0 auto;
}
</style>
